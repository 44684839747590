















































































































import { PropType, defineComponent, ref, watch } from '@vue/composition-api';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
} from '@fortawesome/pro-regular-svg-icons';
import RestoreAlert from '@/components/RestoreAlert.vue';
import { StepDefinition } from '@/interfaces/step-definition';
import { AssistantMachine } from '@/interfaces/assistant';
import { AssistantType } from '~/consts/assistant-types';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import { getCategoryId, getAssistantRootFrom } from '~/helper/converts';
import { loadStateFromLocalStorage } from '@/lib/storage/local-storage';

const useParams = () => {
  const appDiv = document.getElementById(process.env.VUE_APP_SITE_NAME);

  const paramOpen = appDiv?.getAttribute('data-open');
  const paramStepid = appDiv?.getAttribute('data-stepid');
  const paramAssistant = appDiv?.getAttribute('data-assistant');

  return { paramOpen, paramStepid, paramAssistant };
};

export default defineComponent({
  components: {
    RestoreAlert,
  },
  props: {
    assistants: Array<AssistantType>,
    machine: {
      required: true,
      type: Object as PropType<AssistantMachine>,
    },
    steps: {
      required: true,
      type: Array as PropType<StepDefinition[]>,
    },
  },
  setup: (props) => {
    const { send, restoreState, currentStep } = props.machine;

    const assistantStarted = ref(false);

    const { paramOpen, paramStepid, paramAssistant } = useParams();
    const assistantActive: AssistantType =
      AssistantType[paramAssistant as string];

    const open = ref(paramOpen === 'true' || false);
    const content = ref('');
    const isActive = ref<null | AssistantType>(assistantActive || null);

    const stepIds = Object.values(StepId);
    const stepId = ref(StepId[paramStepid as string]);

    if (stepId.value && stepIds.includes(stepId.value)) {
      send(`JUMP_TO_${stepId.value.toUpperCase()}`);
    }

    const toggleSidebar = () => {
      open.value = !open.value;
    };

    const toggleContent = (assistant: AssistantType) => {
      if (isActive.value !== assistant) {
        props.machine.restart();
        assistantStarted.value = true;
        send(`JUMP_TO_${assistant.toUpperCase()}`);
        isActive.value = assistant;
      }
    };

    const restoreToggleState = () => {
      const persistedState = loadStateFromLocalStorage();
      if (!currentStep.value) {
        return;
      }
      const categoryId = persistedState?.category
        ? persistedState?.category
        : currentStep.value.categoryId;
      currentStep.value.categoryId = categoryId as StepCategoryId;
      isActive.value = getAssistantRootFrom(categoryId as string);
    };

    const addToggleListener = () => {
      const toggleButtons = document.querySelectorAll('.stawag-estore-toggle');

      if (toggleButtons.length > 0) {
        toggleButtons.forEach((toggleButton) => {
          toggleButton.addEventListener('click', () => {
            props.machine.restart();
            assistantStarted.value = true;

            const toggleOpen = () => {
              const categoryId: AssistantType =
                AssistantType[
                  toggleButton.getAttribute('data-assistant') as string
                ];
              isActive.value = getAssistantRootFrom(categoryId as string);
              stepId.value = toggleButton.getAttribute('data-stepid') as StepId;
              open.value = true;
              send(`JUMP_TO_${stepId.value.toUpperCase()}`);
            };
            setTimeout(toggleOpen, 300);
          });
        });
      }
    };

    addToggleListener();

    watch(open, () => {
      const overlay = document.querySelector('.overlay');
      if (open.value) {
        overlay?.classList.remove('hide');
      } else {
        overlay?.classList.add('hide');
      }
    });

    return {
      open,
      content,
      isActive,
      getCategoryId,
      toggleSidebar,
      toggleContent,
      faArrowUp,
      faArrowDown,
      faArrowLeft,
      faArrowRight,
      AssistantType,
      assistantStarted,
      restoreState,
      restoreToggleState,
    };
  },
});
