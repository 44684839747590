import { Buffer } from 'buffer';
import axios, { AxiosRequestHeaders } from 'axios';

import { AssistantContext } from '~/steps/steps';
import { StepCategoryId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';

import { getApiToken } from './api-token';
import { getFormattedData } from '~/helper/format';

export interface FileData {
  filename: string;
  contentType: string;
  content: string;
  encoding: string;
}

interface MailData {
  mailSubject: string;
  mailBody: string;
  mailFromAddress: string;
  mailToAddress: string;
  mailBccAddress?: string;
  s3BucketName?: string;
  s3FileAttachmentPaths?: Array<string>;
  siteFileAttachments?: Array<FileData>;
}

export const sendMail = async (mailData: MailData): Promise<void> => {
  try {
    let apiToken = '';
    const headers: AxiosRequestHeaders = {};

    const hasToSendToken =
      process.env.VUE_APP_REQUIRES_TOKEN_WHEN_SEND_EMAIL === 'true';

    if (hasToSendToken) {
      // if hasToSendToken is true, we know that VUE_APP_HEADER_NAME_FOR_TOKEN_USED_WHEN_SEND_EMAIL was set
      const headerNameForToken =
        process.env.VUE_APP_HEADER_NAME_FOR_TOKEN_USED_WHEN_SEND_EMAIL;

      const staticToken = process.env.VUE_APP_STATIC_TOKEN_FOR_SEND_EMAIL;

      apiToken = staticToken.length > 0 ? staticToken : await getApiToken();

      headers[headerNameForToken] = apiToken;
    }

    const base64EncodedMailData = Buffer.from(
      JSON.stringify(mailData),
      'utf8',
    ).toString('base64');

    return axios.post(
      `${process.env.VUE_APP_API_URL}/mail`,
      {
        data: base64EncodedMailData,
      },
      {
        headers: headers,
      },
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error('An error occurred while sending the mail.');
  }
};

export const getMailToAddress = (category: StepCategoryId) => {
  switch (category) {
    case StepCategoryId.HEATING:
      return process.env.VUE_APP_MAIL_TO_ADDRESS_HEATING;
    case StepCategoryId.EMOBILITY:
      return process.env.VUE_APP_MAIL_TO_ADDRESS_EMOBILITY;
    case StepCategoryId.SOLAR:
      return process.env.VUE_APP_MAIL_TO_ADDRESS_SOLAR;
  }
  return '';
};

export const getContentString = (
  steps: StepDefinition[],
  assistantContext: AssistantContext,
  categories: StepCategoryId[],
): string => {
  let contentString = '';

  steps = steps.filter(
    (step) =>
      categories &&
      categories.includes(step.categoryId as StepCategoryId) &&
      step.fields &&
      step.fields.length > 0,
  );

  const categoryId = categories[0] as StepCategoryId;
  const summary = getFormattedData({
    categoryId,
    assistantContext,
    steps,
    // target: OutputFormatterTypes.SUMMARY,
  });

  summary.map((category) => {
    category.steps.map((summaryStep, index) => {
      contentString += `<h2>${summaryStep.title}</h2>`;

      summaryStep.fields.map((field) => {
        if (field.formattedLabel === false && field.formattedValue === false) {
          return;
        }
        if (
          field.formattedLabel !== false &&
          field.formattedValue !== false &&
          field.formattedValue !== '–'
        ) {
          contentString += `<p><strong>${field.formattedLabel}:</strong> ${field.formattedValue}</p>`;
        }
      });

      if (index < category.steps.length - 1) {
        contentString += '<hr />';
      }
    });
  });

  return contentString;
};
